import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './views/LoginPage.vue';

const routes = [
  {
    path: '/',
    redirect: '/new',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/new',
    name: 'new-treatment-plan',
    component: () => import('./views/NewTreatmentPlan.vue'),
  },
  {
    path: '/new/builder',
    name: 'new-builder',
    component: () => import('./views/CalendarBuilder.vue'),
  },
  {
    path: '/edit/builder',
    name: 'edit-builder',
    component: () => import('./views/CalendarBuilder.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('./views/AdminContainer.vue'),
    children: [
      {
        path: 'medications',
        name: 'admin-medications',
        alias: '',
        component: () => import('./views/MedicationsList.vue'),
      },
      {
        path: 'medications/create',
        name: 'admin-medications-create',
        component: () => import('./views/MedicationsCreate.vue'),
      },
      {
        path: 'medications/:id/edit',
        name: 'admin-medications-edit',
        component: () => import('./views/MedicationsEdit.vue'),
        props: true,
      },
      {
        path: 'treatments',
        name: 'admin-treatments',
        component: () => import('./views/TreatmentPlanList.vue'),
      },
      {
        path: 'treatments/create',
        name: 'admin-treatments-create',
        component: () => import('./views/TreatmentPlanCreate.vue'),
      },
      {
        path: 'treatments/:id/edit',
        name: 'admin-treatments-edit',
        component: () => import('./views/TreatmentPlanEdit.vue'),
        props: true,
      },
      {
        path: 'users',
        name: 'admin-users',
        component: () => import('./views/UserList.vue'),
      },
      {
        path: 'users/create',
        name: 'admin-users-create',
        component: () => import('./views/UserCreate.vue'),
      },
      {
        path: 'users/:id/edit',
        name: 'admin-users-edit',
        component: () => import('./views/UserEdit.vue'),
        props: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
