import { createClient, makeOperation } from '@urql/vue';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { authExchange } from '@urql/exchange-auth';
import jwtDecode from 'jwt-decode';
import router from '@/router';
import LogoutMutation from '@/queries/Logout';
import { getAccessToken, setAccessToken } from './accessToken';


const allowedMutations = ['login', 'logout'];

const client = createClient({
  url: `${process.env.VUE_APP_BACKEND_URL}graphql`,
  exchanges: [
    authExchange({
      addAuthToOperation: ({ authState, operation }) => {
        if (!authState || !authState.token) {
          return operation;
        }
        const fetchOptions = typeof operation.context.fetchOptions === 'function'
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {};

        const token = getAccessToken();

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: token ? `Bearer ${token}` : '',
            },
          },
        });
      },
      didAuthError: ({ error }) => error.graphQLErrors
        .some(e => e.message === 'not authenticated'),
      getAuth: async ({ mutate }) => {
        const result = await fetch(`${process.env.VUE_APP_BACKEND_URL}refresh_token`, {
          credentials: 'include',
          method: 'POST',
        });
        const { ok, accessToken } = await result.json();
        if (ok && accessToken) {
          setAccessToken(accessToken);
          return { token: accessToken };
        }
        console.log('No Auth here...');
        setAccessToken('');
        const logoutResult = await mutate(LogoutMutation);
        console.log(logoutResult);
        if (logoutResult) {
          router.push({ name: 'login' });
        }
        return null;
      },
      willAuthError: ({ operation, authState }) => {
        if (!authState) {
          const isLoginMutation = operation.kind === 'mutation'
            && operation.query.definitions.some(definition => (
              definition.kind === 'OperationDefinition'
              && definition.selectionSet.selections.some(node => (
                node.kind === 'Field' && allowedMutations.includes(node.name.value)
              ))
            ));
          console.log(operation, 'IS LOGIN', isLoginMutation);
          if (isLoginMutation) {
            return false;
          }
          return true;
        }
        const jwtData = jwtDecode(authState.token);
        if (Date.now() >= jwtData.exp * 1000) {
          return true;
        }
        return false;
      },
    }),
    multipartFetchExchange,
  ],
  fetchOptions: () => ({
    credentials: 'include',
  }),
});

export default client;
