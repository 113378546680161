<script>
import { useMutation } from '@urql/vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import LogoutMutation from '@/queries/Logout';
import { setAccessToken } from '@/accessToken';

export default {
  props: {
    user: Object,
  },
  setup() {
    const { executeMutation: logoutUser } = useMutation(LogoutMutation);
    const router = useRouter();
    const store = useStore();

    const onLogout = () => {
      logoutUser()
        .then((result) => {
          if (result) {
            setAccessToken('');
            router.push({ name: 'login' });
            store.commit('setUser', null);
          }
        });
    };
    return {
      onLogout,
    };
  },
};
</script>
<template>
  <div class="dropdown">
    <div class="button">
      {{ user.name }}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' class="down-caret">
        <path d='M6 8l-1 1l5 5l5-5l-1-1l-4 4l-4-4z'/>
      </svg>
    </div>
    <div class="menu">
      <!--<span class="menu-item">
        Profile
      </span>-->
      <span class="menu-item" @click="onLogout">
        Logout
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.down-caret {
  width: 25px;
  fill: $textPrimary;
  stroke: $textPrimary;
  padding-left: .5rem;
}
.dropdown {
  position: relative;
  color: $textPrimary;

  &:hover {
    .menu {
      display: flex;
    }
  }
}
.button {
  height: $NAV_BAR_HEIGHT;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0 1.5rem;
}
.menu {
  width: 100%;
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  display: none;

  &:before {
    content: '';
    background: white;
    width: 100%;
    height: 4px;
    position: absolute;
    top: -4px;
    left: 0;
  }
}
.menu-item {
  padding: 1rem 1.5rem;
  transition: all .25s;
  cursor: pointer;

  &:hover {
    background: rgba(0,0,0,.05);
  }
}
</style>
