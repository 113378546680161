<template>
  <div>
    <NavBar @adminClick="model.adminMenuOpen = !model.adminMenuOpen" :user="userData"/>
    <AdminBar :visible="model.adminMenuOpen" :user="userData" v-if="user"/>
    <router-view />
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue';
import AdminBar from '@/components/AdminBar.vue';
import { reactive, computed, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useQuery } from '@urql/vue';
import { useStore } from 'vuex';
// import { setAccessToken } from '@/accessToken';
import getCurrentUserQuery from '@/queries/GetMe';

export default {
  components: {
    NavBar,
    AdminBar,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const model = reactive({
      adminMenuOpen: false,
    });

    if (router.currentRoute.value.fullPath.includes('admin')) {
      model.adminMenuOpen = true;
    }

    /* fetch(`${process.env.VUE_APP_BACKEND_URL}refresh_token`,
      { credentials: 'include', method: 'POST' })
      .then(async (response) => {
        const { accessToken, ok } = await response.json();

        if (!ok) {
          router.push({ name: 'login' }, () => {});
        }

        setAccessToken(accessToken);
      }); */

    const currentUserResult = useQuery({
      query: getCurrentUserQuery,
    });

    watchEffect(() => {
      console.log('Current User', currentUserResult);
      if (currentUserResult.data.value && currentUserResult.data.value.me) {
        store.commit('setUser', currentUserResult.data.value.me);
      }
    });

    const userData = computed(() => store.state.user);

    return {
      model,
      userData,
    };
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700&display=swap');
body {
  font-family: 'Nunito', sans-serif;
  margin-top: 4rem;
}
</style>
