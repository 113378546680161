import { createApp } from 'vue';
import axios from 'axios';
// import VueAxios from 'vue-axios';
import urql from '@urql/vue';
import mitt from 'mitt';

import App from './App.vue';
import router from './router';
import store from './store';
import Config from './config';
import urqlClient from './urqlClient';

axios.defaults.baseURL = Config.apiBaseURL;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

// Vue.use(VueAxios, axios);

const EventBus = mitt();

createApp(App)
  .use(router)
  .use(store)
  .use(urql, urqlClient)
  .provide('EventBus', EventBus)
  .mount('#app');
