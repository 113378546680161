<script>
export default {
  props: {
    visible: Boolean,
    user: Object,
  },
  setup() {
    const permittedAdminRoles = ['admin', 'super'];
    const permittedSuperRoles = ['super'];
    return {
      permittedSuperRoles,
      permittedAdminRoles,
    };
  },
};
</script>
<template>
  <div :class="['admin-bar', { visible }]">
    <div class="container">
      <router-link
        :to="{ name: 'admin-medications' }"
        class="nav-link"
        v-if="permittedAdminRoles.includes(user.role)"
      >
        Medications
      </router-link>
      <router-link
        :to="{ name: 'admin-treatments' }"
        class="nav-link"
        v-if="permittedAdminRoles.includes(user.role)"
      >
        Treatment Plans
      </router-link>
      <router-link
        :to="{ name: 'admin-users' }"
        class="nav-link"
        v-if="permittedSuperRoles.includes(user.role)"
      >
        Users
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.admin-bar {
  background: $brandPrimary;
  width: 100%;
  height: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all .25s;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  padding: 0 1.5rem;
  z-index: 9999;

  &.visible {
    top: 4rem;
  }
}

.container {
  max-width: $containerWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.nav-link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $textAdminBar;
  padding: 0 1rem;
  border-bottom: none;
  border-top: none;
  box-sizing: border-box;
  transition: all .15s;

  &:hover {
    border-bottom: 3px solid rgba($textAdminBar, 0.5);
    border-top: 3px solid rgba(255,255,255,0);
  }

  &.router-link-active {
    border-bottom: 3px solid $textAdminBar;
    border-top: 3px solid rgba(255,255,255,0);
    color: $textAdminBar;
  }
}
</style>
