<script>
export default {
  props: {
    type: String,
    size: String,
  },
};
</script>
<template>
  <button
    :class="['button', type, size]"
  >
    <slot></slot>
  </button>
</template>
<style lang="scss" scoped>
.button {
  background: $BRAND_PRIMARY;
  border: none;
  height: $INPUT_HEIGHT;
  font-size: 1rem;
  color: white;
  border-radius: 5px;
  padding: 0 2rem;
  cursor: pointer;

  &.primary {
    background: $BRAND_PRIMARY;
    color: white;
  }
  &.secondary {
    background: $GRID_ITEM_COLOR;
    color: unset;
  }
  &.normal {
    height: $INPUT_HEIGHT;
  }
  &.error {
    background: $ERROR_COLOR;
  }
  &.small {
    height: 1.5rem;
    padding: 0 .5rem;
    font-size: .8rem;
    border-radius: 3px;
  }
  &.medium {
    height: 2rem;
    padding: 0 1rem;
    font-size: 1rem;
  }
  &.full {
    height: 100%;
    width: 100%;
    padding: 0;
  }
}
</style>
