<script>
import { reactive } from 'vue';
import UserDropdown from '@/components/UserDropdown.vue';

export default {
  emits: ['adminClick'],
  props: {
    user: Object,
  },
  components: {
    UserDropdown,
  },
  setup(props, context) {
    const model = reactive({
      adminActive: false,
    });

    function adminClick() {
      context.emit('adminClick');
      model.adminActive = !model.adminActive;
    }

    const permittedRoles = ['user', 'admin', 'super'];
    const permittedAdminRoles = ['admin', 'super'];

    return {
      model,
      adminClick,
      permittedRoles,
      permittedAdminRoles,
    };
  },
};
</script>
<template>
  <div class="bar">
    <div class="container">
      <div class="logo">
        <img src="@/assets/logo_flat.svg" />
      </div>
      <div class="nav-links">
        <router-link
          :to="{ name: 'new-treatment-plan' }"
          class="nav-link" v-if="user && permittedRoles.includes(user.role)"
        >
          New Plan
        </router-link>
        <!--<router-link :to="{ name: 'edit-builder' }" class="nav-link">
          Find Plan
        </router-link>-->
        <router-link
          :to="'/admin'"
          class="nav-link" v-if="user && permittedRoles.includes(user.role)"
        >
          Admin
        </router-link>
        <!--<router-link to="home" class="nav-link">
          Logout
        </router-link>-->
        <UserDropdown v-if="user" :user="user" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .bar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $NAV_BAR_HEIGHT;
    background: $bgPrimary;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    z-index: 99999;
  }
  .container {
    max-width: $containerWidth;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding-left: 1.5rem;
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 2.5rem;
      width: auto;
    }
  }

  .nav-links {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
  }

  .nav-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $textPrimary;
    padding: 0 1.5rem;
    border-bottom: none;
    border-top: none;
    box-sizing: border-box;
    transition: all .15s;

    &:hover {
      border-bottom: 3px solid rgba($textPrimary, 0.5);
      border-top: 3px solid rgba(255,255,255,0);
    }

    &.router-link-active {
      border-bottom: 3px solid $brandPrimary;
      border-top: 3px solid rgba(255,255,255,0);
      color: $brandPrimary;
    }
  }
</style>
