import { createStore } from 'vuex';

export default createStore({
  state: {
    calendarDays: [],
    regimen: '',
    bloodClot: null,
    antiViral: null,
    preMeds: [],
    patientId: '',
    startDate: '',
    treatmentPlan: null,
    user: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setCalendarDays(state, payload) {
      state.calendarDays = payload;
    },
    setBloodClot(state, payload) {
      state.bloodClot = payload;
    },
    setAntiViral(state, payload) {
      state.antiViral = payload;
    },
    setPreMeds(state, payload) {
      state.preMeds = payload;
    },
    setPatientId(state, payload) {
      state.patientId = payload;
    },
    setStartDate(state, payload) {
      state.startDate = payload;
    },
    setTreatmentPlan(state, payload) {
      state.treatmentPlan = payload;
    },
    setRegimen(state, payload) {
      state.regimen = payload;
    },
  },
  actions: {

  },
});
