<template>
  <div class="input">
    <label>{{label}} <small>{{hint}}</small></label>
    <textarea v-model="model" v-if="type === 'textarea'" :maxlength="maxLength" />
    <div class="file-upload-wrapper" v-else-if="type === 'file'" :data-text="fileText">
      <input name="file-upload-field" type="file" class="file-upload-field" @change="onFileChange">
    </div>
    <input v-else :type="type" v-model="model" @change="onFileChange" :maxlength="maxLength" />
    <span class="error" v-if="error">
      {{errorMessage}}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    hint: String,
    modelValue: [String, Number],
    type: {
      type: String,
      default: 'text',
    },
    error: Boolean,
    errorMessage: String,
    maxLength: Number,
  },
  emits: {
    fileChange: Object,
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  data() {
    return {
      fileText: 'Choose a file...',
    };
  },
  methods: {
    onFileChange(e) {
      if (this.type === 'file') {
        if (e.target.files.length > 0) {
          this.fileText = e.target.files[0].name;
        }
        if (e.target.files.length > 1) {
          this.fileText += ` + ${e.target.files.length - 1} more`;
        }
        this.$emit('fileChange', e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .input {
    display: flex;
    flex-direction: column;
  }
  input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid $inputBorder;
    padding: 0 1rem;
    font-size: 1rem;
    font-family: $FONT_FAMILY;
    font-weight: 300;
    color: $textCalendar;
  }
  textarea {
    min-height: 80px;
    border-radius: 5px;
    border: 1px solid $inputBorder;
    padding: 1rem 1rem;
    font-size: 1rem;
    font-family: $FONT_FAMILY;
    font-weight: 300;
    color: $textCalendar;
    resize: vertical;
  }
  label {
    color: $textCalendar;

    small {
      font-size: .8rem;
      opacity: .5;
    }
  }

  .error {
    color: $ERROR_COLOR;
  }

  .file-upload-wrapper {
    $height: 40px;

    position: relative;
    width: 100%;
    height: $height;

    &:after {
      content: attr(data-text);
      font-size: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      padding: 10px 15px;
      display: block;
      width: calc(100% - 40px);
      pointer-events: none;
      z-index: 20;
      height: $height - 20px;
      line-height: $height - 20px;
      color: $textCalendar;
      border-radius: 5px 10px 10px 5px;
      border: 1px solid $inputBorder;
      font-weight: 300;
    }

    &:before {
      content: 'Upload';
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      height: $height;
      background: $BRAND_PRIMARY;
      color: #fff;
      z-index: 25;
      font-size: 1rem;
      line-height: $height;
      padding: 0 15px;
      pointer-events: none;
      border-radius: 0 5px 5px 0;
      border: 1px solid $BRAND_PRIMARY;
    }

    &:hover {
      &:before {
        background: darken($BRAND_PRIMARY, 10%);
      }
    }
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99;
      height: $height - 20px;
      margin: 0;
      padding: 0;
      display: block;
      cursor: pointer;
      width: 100%;
    }
  }
</style>
