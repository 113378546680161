<script>
import { reactive } from 'vue';
import { useMutation, useQuery } from '@urql/vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import FormInput from '@/components/form/FormInput.vue';
import Button from '@/components/Button.vue';
import LoginUserMutation from '@/queries/LoginUser';
import getCurrentUserQuery from '@/queries/GetMe';
import { setAccessToken } from '@/accessToken';

export default {
  components: {
    FormInput,
    Button,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const model = reactive({
      email: '',
      password: '',
      globalError: '',
    });

    const errors = reactive({
      email: '',
      password: '',
    });

    const resetErrors = () => {
      model.globalError = '';
      Object.keys(errors).forEach((key) => {
        errors[key] = '';
      });
    };

    const checkErrors = () => {
      resetErrors();
      let hasErrors = false;
      if (model.email.length <= 0) {
        errors.email = 'This field is required.';
        hasErrors = true;
      }
      if (model.password.length < 6) {
        errors.password = 'This field is required.';
        hasErrors = true;
      }
      return hasErrors;
    };

    const { executeMutation: loginUser } = useMutation(LoginUserMutation);

    const currentUserResult = useQuery({
      query: getCurrentUserQuery,
    });

    const login = () => {
      const hasErrors = checkErrors();

      if (hasErrors) {
        return;
      }

      const input = {
        email: model.email,
        password: model.password,
      };

      loginUser({ ...input })
        .then((result) => {
          if (result.errors) {
            model.globalError = 'Something happened.';
          }
          setAccessToken(result.data.login.accessToken);
          currentUserResult.resume();
          router.push({ name: 'new-treatment-plan' });
          return currentUserResult.executeQuery({
            requestPolicy: 'network-only',
          });
        })
        .then((result) => {
          console.log('RESULT: ', result);
          if (result.data.value && result.data.value.me) {
            store.commit('setUser', result.data.value.me);
          }
        });
    };

    return {
      model,
      errors,
      login,
    };
  },
};
</script>
<template>
  <div class="container">
    <h1>Login</h1>
    <div class="error-banner" v-if="model.globalError.length > 0">
      {{model.globalError}}
    </div>
    <div class="form">
      <div class="form-input">
        <FormInput
          label="Email"
          v-model="model.email"
          :error="errors.email.length > 0"
          :errorMessage="errors.email"
          type="email"
        />
      </div>
      <div class="form-input">
        <FormInput
          label="Password"
          v-model="model.password"
          :error="errors.password.length > 0"
          :errorMessage="errors.password"
          type="password"
        />
      </div>
      <div class="form-input button">
        <Button @click="login">
          Login
        </Button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .error-banner {
    background: rgba($ERROR_COLOR, .2);
    border-left: 5px solid $ERROR_COLOR;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    color: $ERROR_COLOR;
  }

  .container {
    max-width: $loginContainerWidth;
    margin: 0 auto;
    padding: 1rem;
    padding-top: 4rem;
  }
  h1 {
    @include header;
    text-align: center;
    padding: 1rem 0;
    font-size: 1.5rem;
    flex: 1;
  }

  .error {
    color: $ERROR_COLOR;
  }

  .button {
    width: 100%;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @include tablet {
      grid-template-columns: 1fr;
    }
  }
</style>
