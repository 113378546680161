import { gql } from 'urql/core';

export default gql`
query getMe {
  me {
    id,
    name,
    email,
    role,
  }
}
`;
